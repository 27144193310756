<template>
  <b-input-group
    :disabled="disable" :state="fieldValid" style="position: relative"
    :variant="multitip?'light':''"
    >
    <b-input-group-prepend v-if="def.type==='currency'" :state="fieldValid" :disabled="disable">
      <b-button :class="validClass" tabindex="-1" :variant="multitip?'light':''">$</b-button>
    </b-input-group-prepend>
    <b-input
        v-if="!def.val || isType.primitive(def.val)"
        :class="{transparent:multitip || (def.val && def.val > 1000)}"
      :style="{background: multitip ? lioTheme.lightGray : 'white'}"
      @keypress="skipNonNumberInput" :chain="def.chain"
      :id="def.guid" @focus="touch(true)" @blur="touch(false)"
      :state="fieldValid" v-model="def.val" type="number" @change="constrainInput"
      :step="1" :autofocus="autofocus"
      :placeholder="def.placeholder" :disabled="disable" :title="!fieldValid ? 'Enter a valid number' : null"
      :min="def.min" :max="def.max" v-b-tooltip.hover="def.isValid ? '' : def.validationTip"/>
    <span class="currency-overlay" :class="def.type" v-if="multitip || (def.val && def.val > 1000)">
      {{ multitip ? '[multiple]' : def.noformat ? def.val : currency(def.val,false,false) }}
    </span>
  </b-input-group>
</template>

<script>
import fieldmix from '@/components/fields/fieldmix';

export default {
  computed: {

  },
  methods: {
    skipNonNumberInput(event) {
      if (!/\d/.test(event.key)) {
        return event.preventDefault();
      }
    },
    constrainInput(){
      let v = this.def.val;
      if (v === ''){
        this.def.val = null;
      }
      if (['currency', 'int'].includes(this.def.type) && v.includes('.')) {
        this.def.val = v.split('.')[0];
      }
      if (this.def.constrainRange){
        if (Number(v) < this.def.min){
          this.def.val = this.def.min;
        }
        if (Number(v) > this.def.max){
          this.def.val = this.def.max;
        }
      }
    }
  },

  mixins: [fieldmix],
  name: 'fieldtype_number'
};
</script>

<style scoped lang="scss">
  @import "../../assets/scss/variables";
  .currency-overlay{
    pointer-events: none;
    position:absolute;
    top:7px;left:44px;
    &.int{
      left:12px;
    }
    &.currency{
      left:44px;
    }
  }
  input.transparent{
    color:transparent;
    &:active,&:focus{
      color:#333;
    }
  }
</style>
