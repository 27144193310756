import {titleCased} from '@/lib/util';

export const listPart = (list, {only, except}) =>
  list.filter(({key}) =>
    (!only || only.includes(key)) && (!except || !except.includes(key)));

const clarionList = labels => labels.map(lbl => {
  return {key: lbl, lbl};
});

const buildingGroupTypes = [//clarionified
  {key: 'I', lblHead: 'Group I', lbl: ` - Buildings with a masonry or wood frame, 1-3 stories in height (not including basement)`},
  {key: 'II', lblHead: 'Group II', lbl: ` - Buildings with a steel or concrete frame, or of reinforced masonry construction, which are 60 feet or less in height`},
  {key: 'III', lblHead: 'Group III', lbl: ` - Buildings with a steel or concrete frame, which are greater than 60 feet in height`}
];

const preO2 = 'Prior to 2002';
const post02 = '2002 or Later';
const ynList = clarionList(['Yes', 'No']);
const roofDeckTypes = clarionList(['Wood', 'Metal', 'Reinforced Concrete']);

const roofDeckAttachmentTypes = [//todo:clarionify
  {key: 'A', lbl: 'Level A'},
  {key: 'B', lbl: 'Level B'},
  {key: 'C And D Dimensional Lumber Deck', lbl: 'Levels C and D Dimensional Lumber'},
  {key: 'Not Applicable', lbl: 'Not Applicable'}
];

const roofConnectionTypes = clarionList(['Toe Nails', 'Clips', 'Single Wraps', 'Double Wraps', 'Not Applicable']);

const roofCoverTypes = clarionList(['FBC Equivalent', 'Non-FBC Equivalent']);

const terrainTypes = [//clarionified
  {key: 'B', lbl: 'Exposure B'},
  {key: 'C', lbl: 'Exposure C'},
  {key: 'B and C', lbl: 'Exposure B and C'},
  {key: 'High Velocity Hurricane Zone', lbl: 'High Velocity Hurricane Zone'}
];

const designExposureTypes = [//clarionified
  {key: 'Standard', lbl: 'Standard'},
  {key: 'B', lbl: 'Exposure B'},
  {key: 'C', lbl: 'Exposure C'},
  {key: 'D', lbl: 'Exposure D'}
];

const designSpeedTypes = [//clarionified
  {key: '90', lbl: '90 MPH'},
  {key: '100', lbl: '100 MPH'},
  {key: '<=100', lbl: '100 MPH or Less'},
  {key: '110', lbl: '110 MPH'},
  {key: '120', lbl: '120 MPH'},
  {key: '>=120', lbl: '120 MPH or greater'},
  {key: '130', lbl: '130 MPH'}
];

const code76 = '1982 Or Earlier (Standard Building Code 1976)';
const code88 = '1983 To 2001 (Standard Building Code 1988)';
const designCodeTypes = clarionList([code76, code88]);

const gustSpeedTypes = listPart(designSpeedTypes, {only: ['100', '110', '>=120']});//clarionified

const openProtectionTypes = clarionList(['None', 'Hurricane', 'Yes', 'No']);

const pressureDesignTypes = clarionList(['Enclosed', 'Partially Enclosed']);

const roofShapeTypes = clarionList(['Flat', 'Gable End With Bracing', 'Hip']);

//todo: for all sc/al types (mostly all below here), get clarionification
const scBuildingTypes = [
  {key: 'I', lblHead: 'Type I', lbl: ` - 1 - 3 stories`},
  {key: 'II', lblHead: 'Type II', lbl: ` - 4 or more stories`}
];

const scVals = vals =>
  [...vals, {key: 'Unknown', lbl: 'Unknown'}];


const territoryTypes = clarionList(['Coastal and Seacoast Areas', 'Remainder of State']);

const scRoofShapeTypes = clarionList(['Flat', 'Gable End With bracing', 'HIP', 'Unknown']);
//todo: unconfirmed

const buildingCodeTypes = clarionList(['SCBC Equivalent', 'Non-SCBC Equivalent']);

const roofDeckBuildingTypes = clarionList(['A', 'B', 'C & D', 'Reinforced Concrete Roof Deck']);

const roofToWallTypes = clarionList(['Toe Nails', 'Clips', 'Single/Double Wraps']);

const openingProtectionTypes = clarionList([
  'None',
  'Tempered, Heat Strengthened Or Laminated Glass Or Insulating Glass Units With No Engineered Shutters',
  'Engineered Shutters'
]);

const doorStrengthTypes = clarionList(['Reinforced Single Width Doors', 'All Other']);

const otmot5 = 'Other Than Metal and Older Than 5 Years';
const metalNoSD = 'Metal and Less Than or Equal To 10 Years Old With No Subdecking';
const roofCharacteristicTypes = clarionList([
  'Metal and Older Than 10 Years',
  otmot5,
  metalNoSD,
  'All Other'
]);

const occupancyTypes = [
  {key: 'commercial', lbl: 'Commercial'},
  {key: 'residential', lbl: 'Residential'}
];

const ffslCounties = 'Mobile,Baldwin,Washington,Escambia,Covington,Geneva,Houston';
const certificateLevelTypes = clarionList([
  '2006+ IBC', 'Bronze', 'Silver', 'Gold', 'FFSL'
]);

export let aggregatedPairs = {};

const wsFieldMapper = (f, stateCode) => {
  if (!f.lbl){
    f.lbl = titleCased(f.key);
  }
  f.tags = `${stateCode},hasExternalSource`;
  f.group = 'wsMitigation';
  if (!aggregatedPairs[f.key]){
    aggregatedPairs[f.key] = {
      type: f.type ?? 'select',
      states: []
    };
  }
  aggregatedPairs[f.key].states.push(stateCode);
  if (stateCode !== 'FL' && flFields.find(({key}) => key === f.key)){
    f.apiKey = f.key;
    f.key = `${f.key}_${stateCode}`;
  }
  if (!f.type){
    f.type = 'select';
  }

  return f;
};

export const wsEnabledField = {
  key: 'windstormCoveredState', type: 'data',
  group: 'wsMitigation',
  val: {
    $conditional: true,
    $compute: {
      vm: 'wsLossMitigationState'
    }
  }
};


const flFields = [

  {
    key: 'buildingGroup', type: 'radio', lbl: 'Group', height: 'auto', classList: 'w-100 mb-2',
    vals: buildingGroupTypes, title: 'Windstorm loss mitigation',
    active: {
      $conditional: true,
      test: {chain: 'sibling::windstormCoveredState', eq: 'FL'},
      keepVal: true
    }
  },
  {
    key: 'buildingCode', type: 'radio', lbl: 'Built before 2002?',
    vals: [
      {key: preO2, lbl: 'Yes'}, {key: post02, lbl: 'No'}
    ],
    active: {
      $conditional: true,
      test: {chain: 'sibling::buildingGroup', ne: null},
      keepVal: true
    }
  },
  {
    key: 'roofDeck', type: 'select', lbl: 'Roof Deck Type',
    vals: roofDeckTypes,
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', ne: null}, //do not render before pre-02 has been answered
        {
          $or: [
            {chain: 'sibling::buildingGroup', ne: 'I'},
            {chain: 'sibling::buildingCode', eq: post02}
          ]
        }
      ]
    }
  },
  {key: 'roofShape', type: 'select',
    vals: roofShapeTypes,
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', ne: null},
        {chain: 'sibling::buildingGroup', eq: 'I'}
        //{chain: 'sibling::windDebrisRegion', ne: null}
      ]
    }
  },
  {
    key: 'roofCover', type: 'select',
    vals: roofCoverTypes,
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', eq: preO2
    }
  },
  {
    key: 'terrainType', type: 'select',
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', ne: null
    },
    vals: {
      $conditional: true,
      $switch: [
        {
          chain: 'sibling::roofDeck', eq: 'Reinforced Concrete',
          result: listPart(terrainTypes, {except: ['C']})
        }
      ],
      defaultVal: listPart(terrainTypes, {except: ['B and C']})
    }
  },
  {
    key: 'gustWindSpeedOfLocation', type: 'select',
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', eq: post02
    },
    vals: {
      $conditional: true,
      $switch: [
        {
          chain: 'sibling::terrainType',
          includedIn: ['B and C', 'High Velocity Hurricane Zone'],
          result: listPart(gustSpeedTypes, {only: ['>=120']})
        }
      ],
      defaultVal: gustSpeedTypes
    },
    defaultVal: {
      $conditional: true,
      $switch: [{
        chain: 'sibling::terrainType', includedIn: ['B and C', 'High Velocity Hurricane Zone'],
        result: '>=120'
      }],
      defaultVal: null
    }
  },
  {
    key: 'gustWindSpeedOfDesign', type: 'select',
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', eq: post02
    },
    vals: {
      $conditional: true,
      $switch: [
        {
          chain: 'sibling::terrainType',
          includedIn: ['B and C', 'High Velocity Hurricane Zone'],
          result: listPart(gustSpeedTypes, {only: ['>=120']})
        }
      ],
      defaultVal: gustSpeedTypes
    }
  },
  {
    key: 'internalPressureDesign', type: 'select',
    vals: pressureDesignTypes,
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: post02},
        {chain: 'sibling::buildingGroup', eq: 'I'}
      ]
    }
  },
  {
    key: 'windBorneDebrisRegion', type: 'radio',
    lbl: 'Wind-borne Debris Region', vals: ynList,
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', ne: null
    }
  },

  {
    key: 'openingProtection', type: 'radio',
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', ne: null
    },
    vals: {
      $conditional: true,
      $switch: [{
        $and: [
          { chain: 'sibling::buildingCode', eq: post02},
          { chain: 'sibling::buildingGroup', eq: 'I' }
        ],
        result: listPart(openProtectionTypes, {except: ['Yes', 'No']})
      }],
      defaultVal: listPart(openProtectionTypes, {only: ['Yes', 'No']})
    }
  },
  {
    key: 'secondaryWaterResistance', type: 'radio',
    vals: [{key: 'SWR', lbl: 'Yes'}, {key: 'noSWR', lbl: 'No'}],
    active: {
      $conditional: true,
      chain: 'sibling::buildingCode', ne: null
    }
  },
  {
    key: 'roofDeckAttachment', type: 'select',
    vals: roofDeckAttachmentTypes,
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: preO2},
        {chain: 'sibling::buildingGroup', eq: 'I'}
      ]
    }
  },
  {
    key: 'roofWallConnection', type: 'select',
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: preO2},
        {chain: 'sibling::buildingGroup', eq: 'I'}
      ]
    },
    vals: {
      $conditional: true,
      $switch: [{
        chain: 'sibling::roofDeckAttachment', eq: 'na',
        result: listPart(roofConnectionTypes, {only: ['na']})
      }],
      defaultVal: roofConnectionTypes
    },
    defaultVal: {
      $conditional: true,
      $switch: [{chain: 'sibling::roofDeckAttachment', eq: 'na', result: 'na'}],
      defaultVal: null
    }
  },
  {
    key: 'designCode', type: 'select',
    vals: designCodeTypes,
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: preO2},
        {chain: 'sibling::buildingGroup', ne: 'I'}
      ]
    }
  },
  {
    key: 'designSpeed', type: 'select',
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: preO2},
        {chain: 'sibling::buildingGroup', ne: 'I'}
      ]
    },
    vals: {
      $conditional: true,
      $switch: [{
        $and: [
          {chain: 'sibling::terrainType', eq: 'B'},
          {chain: 'sibling::designCode', eq: code76}
        ],
        result: listPart(designSpeedTypes, {only: ['90', '100', '110', '>=120']})
      }, {
        $and: [
          {chain: 'sibling::terrainType', eq: 'B'},
          {chain: 'sibling::designCode', eq: code88}
        ],
        result: listPart(designSpeedTypes, {only: ['90', '100', '110']})
      }, {
        $and: [
          {chain: 'sibling::terrainType', eq: 'C'},
          {chain: 'sibling::designCode', eq: code76}
        ],
        result: listPart(designSpeedTypes, {only: ['<=100', '110', '120', '130']})
      }, {
        $and: [
          {chain: 'sibling::terrainType', eq: 'C'},
          {chain: 'sibling::designCode', eq: code88}
        ],
        result: listPart(designSpeedTypes, {only: ['90', '100', '110']})
      }],
      defaultVal: listPart(designSpeedTypes, {only: ['90', '100', '110', '>=120']})
    }
  },
  {
    key: 'designExposure', type: 'select', lbl: 'Design Exposure',
    active: {
      $conditional: true,
      $and: [
        {chain: 'sibling::buildingCode', eq: preO2},
        {chain: 'sibling::buildingGroup', eq: 'III'}
      ]
    },
    vals: {
      $conditional: true,
      $switch: [{
        chain: 'sibling::designCode', eq: code76,
        result: listPart(designExposureTypes, {only: ['Standard']})
      }],
      defaultVal: listPart(designExposureTypes, {except: ['Standard']})
    },
    defaultVal: {
      $conditional: true,
      $switch: [{chain: 'sibling::designCode', eq: code76, result: 'Standard'}],
      defaultVal: null
    }
  }
].map(f => wsFieldMapper(f, 'FL'));

const scFields = [
  {
    key: 'buildingType', type: 'radio', height: 'auto', classList: 'w-100', title: 'Windstorm loss mitigation',
    vals: scBuildingTypes,
    active: {
      $conditional: true,
      chain: 'sibling::windstormCoveredState', eq: 'SC'
    }
  },
  {
    key: 'territory', type: 'radio', classList: 'w-100', vals: scVals(territoryTypes),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', ne: null
    }
  },
  {
    key: 'roofShape', type: 'select', vals: scRoofShapeTypes,
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', eq: 'I'
    }
  },
  {
    key: 'buildingCode', type: 'select', vals: scVals(buildingCodeTypes),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', ne: null
    }
  },
  {
    key: 'roofDeck', type: 'select', lbl: 'Roof Deck Building Type',
    vals: {
      $conditional: true,
      $switch: [
        {chain: 'sibling::buildingType', eq: 'II', result: scVals(roofDeckTypes)},
        {chain: 'sibling::buildingType', eq: 'I', result: scVals(roofDeckBuildingTypes)}
      ],
      defaultVal: scVals(roofDeckBuildingTypes)
    },
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', ne: null
    }
  },
  {
    key: 'roofWallConnection', type: 'select', vals: scVals(roofToWallTypes),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', eq: 'I'
    }
  },
  {
    key: 'openingProtection', type: 'select',
    vals: scVals(openingProtectionTypes),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', ne: null
    }
  },
  {
    key: 'doorStrength', type: 'select',
    vals: scVals(doorStrengthTypes),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', eq: 'I'
    }
  },
  {
    key: 'secondaryWaterResistance', type: 'select',
    vals: clarionList(['SWR', 'No SWR', 'Not Applicable', 'Unknown']),
    active: {
      $conditional: true,
      chain: 'sibling::buildingType', ne: null
    },
    defaultVal: 'Not Applicable'
  }
].map(f => wsFieldMapper(f, 'SC'));

const alFields = [
  {
    key: 'roofCharacteristics', vals: roofCharacteristicTypes,
    title: 'Windstorm loss mitigation',
    active: {
      $conditional: true,
      chain: 'sibling::windstormCoveredState', eq: 'AL'
    }
  },
  {
    key: 'occupancy',
    vals: {
      $conditional: true,
      $switch: [{
        chain: 'sibling::roofCharacteristics', eq: metalNoSD,
        result: listPart(occupancyTypes, {only: ['commercial']})
      }],
      defaultVal: occupancyTypes
    },
    active: {
      $conditional: true,
      chain: 'sibling::windstormCoveredState', eq: 'AL'
    }
  },
  {
    key: 'certificateLevel',
    vals: {
      $conditional: true,
      $switch: [{
        test: { chain: `selectedChildField||county:includedIn:${ffslCounties}`, eq: true },
        result: certificateLevelTypes
      }],
      defaultVal: listPart(certificateLevelTypes, {except: 'FFSL'})
    },
    active: {
      $conditional: true,
      chain: 'sibling::windstormCoveredState', eq: 'AL'
    }
  }
].map(f => wsFieldMapper(f, 'AL'));

export const windstorm = [wsEnabledField, ...flFields, ...scFields, ...alFields];
// using different keys for 4 SC fields, but want to send to api with same keynames
