<template>
  <div v-if="render"
      :cp="computedProps" :active="def.active"
      class="no-select" :component="`${componentName(def)}, type=${def.type}, readOnly=${def.readOnly}`"
      :class="def.type !== 'data' && (def.active || placeholderMode) ? classList : 'd-none'"
      :style="{
        width:(['text','desc','bool','date','geocode','multi'].includes(def.type)) ? undefined :
        def.type==='autocomplete' ? '100%' : def.width || '50%'
     }">
    <selection-proxy v-if="def.selectionProxy" :def="def"/>
    <valign-mid class="input-row" :h="def.height"
       v-if="def && def.type && def.active"
      v-b-tooltip.hover.leftbottom="saving ? 'Saving quote data. Please wait.' : ''">
      <span v-if="def.readOnly" class="d-inline-block mt-2">
        <span v-if="def.type==='date'">{{isType.date(def.val) ? def.val.toLocaleDateString() : def.val}}</span>
        <span v-else-if="def.isAddress">{{def.address || ''}}</span>
        <span v-else-if="def.type==='yn'">{{def.val === true ? 'Yes' : def.val === false ? 'No' : 'n/a'}}</span>
        <span v-else>{{ def.type === 'currency' ? currency(def.val, true, false) : def.val }}</span>
      </span>

      <div class="d-flex h-100" v-else>
        <component :is="componentName(def)" :def="def">
          <slot></slot>
        </component>
        <a class="right-tip mt-1 pt-1" v-if="multitip" :class="def.type">
          <b-icon-info-circle variant="light" :scale="1.44" :id="`tt${def.chain}`"/>
        </a>
        <b-popover :target="`tt${def.chain}`" triggers="hover" v-if="multitip" placement="right">
          <template #title>Multiple Values in Selection</template>
          <em style="color:white" class="text-left">Set a new value to overwrite all existing values with the new value.</em>
          <strong class="d-block text-left" style="color:white">{{ multitip.length }} Values:</strong>
          <ul class="m-0 p-0 pl-3" style="color:white">
            <li v-for="({lbl,count},i) in multitip" :key="lbl+i" style="color:white;text-align: left">
              <strong style="color:inherit">{{ lbl }}</strong> <em style="color:inherit">({{ count }} building{{ count == 1 ? '' : 's' }})</em>
            </li>
          </ul>
        </b-popover>
      </div>



    </valign-mid>
  </div>
</template>

<script>
import ValignMid from '@/components/util/valign-mid';
import Fieldtype_bool from '@/components/fields/fieldtype_bool';
import Fieldtype_number from '@/components/fields/fieldtype_number';
import Fieldtype_text from '@/components/fields/fieldtype_text';
import Fieldtype_select from '@/components/fields/fieldtype_select';
import Fieldtype_address from '@/components/fields/fieldtype_address';
import Fieldtype_yn from '@/components/fields/fieldtype_yn';
import Fieldtype_date from '@/components/fields/fieldtype_date';
import Fieldtype_multi from '@/components/fields/fieldtype_multi';
import Fieldtype_ynRadio from '@/components/fields/fieldtype_yn-radio';
import Fieldtype_radio from '@/components/fields/fieldtype_radio';
import Fieldtype_autocomplete from '@/components/fields/fieldtype_autocomplete';
import {mapGetters, mapMutations} from 'vuex';
import resize from 'vue-resize-directive';
import {quotemix} from '@/lib/quotemix';
import SelectionProxy from '@/components/util/selection-proxy';
import fieldmix from '@/components/fields/fieldmix';
import Fieldtype_phone from '@/components/fields/fieldtype_phone';
export default {
  data: () => {
    return {
      renderDef: true
    };
  },
  name: 'field',
  directives: {resize},
  components: {
    Fieldtype_phone,
    SelectionProxy,
    Fieldtype_autocomplete,
    Fieldtype_radio, Fieldtype_ynRadio, Fieldtype_multi, Fieldtype_date, Fieldtype_yn, Fieldtype_address,
    Fieldtype_select, Fieldtype_text, Fieldtype_number, Fieldtype_bool, ValignMid
  },
  mixins: [quotemix, fieldmix],
  computed: {
    ...mapGetters('getQuote', ['evalCondition', 'computedVal']),
    render(){
      return !!this.def && this.renderDef;
    },
    watchedProp(){
      let {def} = this;
      if (def.watch){
        return def[def.watch];
      }
      return null;
    },
    componentName(){
      let componentMap = {
        text: 'text',
        txt: 'text',
        select: 'select',
        currency: 'number',
        int: 'number',
        yn: 'yn',
        'yn-radio': 'yn-radio',
        radio: 'radio',
        bool: 'bool',
        date: 'date',
        geocode: 'address',
        multi: 'multi',
        autocomplete: 'autocomplete',
        phone: 'phone'
      };

      return ({type, key}) => {
        if (key === 'location'){
          console.log({key, def: this.def});
        }
        if (componentMap[type]){
          return `fieldtype_${componentMap[type]}`;
        }
        //console.log({type});
        return 'div';
      };
    },
    placeholderMode(){
      return !this.def.active && this.def.keepLayout;
    },

    computedProps(){
      try{
        if (!this.def || !this.def?.page){
          return false;
        }

        let {conditionalProps} = this.def;
        if(conditionalProps) {
          return this.fieldState(this.def);
        }else{
          return undefined;
        }
      }catch(ex){
        return false;
      }
    },
    classList(){
      let classEntries = (this.def.classList ?? '').split(' ');
      if (this.def.readOnly){
        classEntries.push('read-only');
      }
      if (this.def.street2 === false){
        classEntries.push('no-street-2');
      }
      classEntries = classEntries.map(c => [c, c]);
      let list = Object.fromEntries(classEntries);
      list.invis = this.placeholderMode;
      list.geocode = this.def.type === 'geocode';

      return list;
    }
  },
  methods: {
    ...mapMutations('getQuote', ['updateField']),
    updateProps(){
      let vals = this.computedProps;
      if (Array.isArray(vals)) {
        this.def.conditionalProps.forEach((cp, i) => {
          cp.updateCondition(vals[i]);

        });
      }
    }
  },
  props: ['def'],
  watch: {
    watchedProp: {
      handler(){//trigger new component render cycle for stubborn conditionals
        this.renderDef = false;
        this.delayFn(() => this.renderDef = true, 250);
      },
      deep: true
    }
  }
};
</script>

<style scoped lang="scss">
.input-row{
  white-space: nowrap;
  a.right-tip{
    margin-left:8px;
margin-right:-33px;
  }
}
.no-select.geocode{
  height:202px;
  &.read-only{
    height:55px;
  }
  &.no-street-2{
    height:144px;
  }
}
</style>
