<template>
  <div>
    <h3 class="mb-4">Outdoor Property Types</h3>
    <div class="d-flex">
      <div v-for="(chunk, ci) in chunkArray(location.outdoorPropList, 3)" :key="`opt${ci}`">
        <div v-for="def in chunk" :key="def.lbl"
             :class="{
                ['pr-3']: ci !== 2,
                ['pl-3']: ci
            }" class="outdoor-type-row">
          <field :def="def"><span>{{ def.lbl }}</span></field>
          <div v-if="def.hasTag('descriptionRequired') && def.val" style="margin:-16px 0 0 24px">
            <field :def="location.child('describeOther')"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {quotemix} from '@/lib/quotemix';
import Field from '@/components/fields/field';

export default {
  name: 'checklist-op',
  components: {Field},
  mixins: [quotemix],
  props: ['location']
};
</script>


