import {isType} from '@/lib/mytype';
import {format, toDate, utcToZonedTime} from 'date-fns-tz';
import globals from '../store/globals';
import rdfc from 'rfdc';

export const maxOSInt = 2147483647;
//export const minOSInt = -2147483648;

export const dashCased = camel => camel.replace(/\s/g, '-').replace(/[A-Z]/g, m => '-' + m.toLowerCase()).replaceAll('--', '-');
export const camelCased = dashed => dashed.replace(/\s/g, '-').replace(/-([a-z])/g, g => g[1].toUpperCase());
export const titleCased = (s, lcWords = ['of', 'and', 'the', 'to']) => {
  try {
    let tc = str => str.replace(/-([a-z])/g, g => ` ${g[1].toUpperCase()}`).trim();
    s = `${s[0].toUpperCase()}${s.substr(1)}`;

    if (s.includes('-')) {
      s = tc(s);
    } else {
      s = tc(dashCased(s));
    }
    if (lcWords){
      s = s.split(' ').map((word, i) => {
        if (i && lcWords.includes(word.toLowerCase())){
          word = word.toLowerCase();
        }
        return word;
      }).join(' ');
    }
    return s;
  }catch(ex){
    return s || '';
  }
};
//const stripNonAlpha = s => s.replace(/[^A-Za-z]/g, '-').replace(/--/g, '-');
//export const convertCamel = s => camelCased(stripNonAlpha(dashCased(s)));
export const chunkArray = (array, size) => {
  let result = [];
  for (let i = 0; i < array.length; i += size) {
    result.push(array.slice(i, i + size));
  }
  return result;
};

export const pluckAll = (s, from, to) => {
  let items = [];
  while(s.indexOf(from) > -1 && s.indexOf(to) > -1) {
    items.push(s.substring(s.indexOf(from) + from.length, s.indexOf(to)));
    s = `${s.substr(0, s.indexOf(from))}${s.substr(s.indexOf(to) + to.length)}`;
  }
  return items;
};

export const plucks = (s, from, to) => s.substring(s.indexOf(from) + from.length, s.indexOf(to));
const s2Array = v => {
  if (isType.string(v) && v.startsWith('[')){
    v = plucks(v, '[', ']');
    let innerObjs = pluckAll(v, '{', '}');
    if (innerObjs.length){
      //console.log({innerObjs:innerObjs.map(o => s2obj(`{${o}}`))});
      v = innerObjs.map(o => s2obj(`{${o}}`));
    }else {
      v = v.split(',').map(s => s2obj(s.trim()));
    }
  }
  return v;
};
export const s2obj = v => {
  if (v.startsWith('{')) {
    let obj = plucks(v, '{', '}')
      .split(',')
      .map(kv => kv.split(':').map((val, i) =>
        !i ? val.trim() : s2Array(val.trim())));
    return Object.fromEntries(obj);
  }
  return s2Array(v);
};
export const clone = rdfc();

export const sortByKey = (arr, k, asc = true) => {
  let isObject = isType.object(arr);
  if (isObject){
    arr = Object.entries(arr).map(([_tmpKey, obj]) => {
      return {
        ...obj,
        _tmpKey
      };
    });
  }
  let sorted = arr.sort((a, b) => {
    let a1 = k === undefined ? a : a[k];
    let b1 = k === undefined ? b : b[k];
    if (!isNaN(a1) && !isNaN(b1)) {
      return a1 - b1;
    } else if (typeof a1 === 'string' && typeof b1 === 'string') {
      return a1 < b1 ? -1 : a1 > b1 ? 1 : 0;
    }
  });
  if (!asc){
    sorted = sorted.reverse();
  }
  if (isObject){
    let obj = {};
    sorted.forEach(o => {
      //console.log(o._tmpKey, o.count);
      obj[o._tmpKey] = o;
      delete o._tmpKey;
    });
    //let obj = Object.fromEntries(entries);
    console.log(Object.keys(obj));
    return obj;
  }
  return sorted;
};

let pad = n => (`${n}`).padStart(2, '0');

export const longDate = (d = new Date()) => {
  d = new Date(d);
  return `${d.getFullYear()}${pad(d.getMonth() + 1)}${pad(d.getDate())}000000`;
};

export const mmddyyyy = (d = new Date()) => {
  d = new Date(d);
  return `${pad(d.getMonth() + 1)}/${pad(d.getDate())}/${d.getFullYear()}`;
};

export const differenceInCalendarDays = (a, b = new Date()) => {
  return Math.ceil((b - a) / (1000 * 60 * 60 * 24));
};

export const guid = () => {
  const n = () => (Math.random() * 46656) | 0;
  const sid = () => ('000' + n().toString(36)).slice(-3);
  return sid() + sid();
};

export const removeObjNulls = o => Object.fromEntries(
  Object.entries(o).filter(([, val]) => !isType.nullOrUndef(val))
);

export const cleanseParams = (params, decode = false) => {

  const encodeXChars = val => (isType.string(val)) ?
    encode(val) : isType.object(val) ?
      Object.fromEntries(traverse(val)) :
      Array.isArray(val) ? val.map(v => encodeXChars(v)) : val;

  const encode = val => {
    if (decode){
      val = val.replaceAll('&amp;', '&');
      val = val.replaceAll('&amp;', '&');//double replace == intended
      return val.replaceAll('&gt;', '>').replaceAll('&lt;', '<')
        .replaceAll(`&apos;`, `'`).replaceAll('&quot;', '"');
    }
    val = val.replaceAll('&', '&amp;');
    return val.replaceAll('>', '&gt;')
      .replaceAll('<', '&lt;').replaceAll(`'`, '&apos;')
      .replaceAll('"', '&quot;');
  };
  const traverse = o => Object.entries(o).map(([key, val]) => [key, encodeXChars(val)]);

  return encodeXChars(params);

};

export const decodeXml = val => cleanseParams(val, true);

// string input - MM/dd/yyyy = date output
export const parseOsDate = (d) => {
  const [month, day, year] = d.split('/');
  const isoDateFormat = `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`;
  return parseShortDate(isoDateFormat);
};
// date input = string output - yyyy-MM-dd
export const formatShortDate = (d) => {
  return format(utcToZonedTime(d, {timeZone: globals.OS.DATE.TIMEZONE }), globals.OS.DATE.STRING_OUTPUT_FORMAT);
};
// input string - yyyy-MM-dd = date output
export const parseShortDate = (d) => {
  return toDate(d, { timeZone: globals.OS.DATE.TIMEZONE });
};
// input date = output string - 'MM/dd/yyyy'
export const formatOsDate = (d) => {
  return format(utcToZonedTime(d, {timeZone: globals.OS.DATE.TIMEZONE }), globals.OS.DATE.STRING_INPUT_FORMAT);
};

export const daysAgo = d => {
  try {
    let oneDay = 24 * 60 * 60000;
    d = clone(d);
    if (!isType.date(d)) {
      d = new Date(d);
    }
    return Math.ceil((Date.now() - d.valueOf()) / oneDay);
  }catch(ex){
    console.warn({daysAgo: d, ex});
    return null;
  }
};

export const parseUrl = (url, payload) => {
  if(!url.includes(':')) return url;
  return url.split('/').map(p => {
    let param = p.includes(':') ? p.split(':')[1] : null;
    if (param && payload[param]){
      p = payload[param];
      delete payload[param];
    }else if (p.includes(':')){
      p = '';//do not include param placeholder
    }
    return p;
  }).join('/');
};

export const debounce = (func, wait, immediate) => {
  let timeout;
  return function(...args) {
    const context = this;
    const later = function() {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };
    const callNow = immediate && !timeout;
    clearTimeout(timeout);
    timeout = setTimeout(later, wait);
    if (callNow) func.apply(context, args);
  };
};

export const getChainedVal = (chain, o) => {
  try {
    let v = o;
    chain.split('.').forEach(k => v = v[k]);
    return v;
  }catch(ex){
    return null;
  }
};
