<template>
<table style="width:100%; table-layout: fixed">
  <colgroup v-if="labelW && fieldW">
    <col :width="labelW"/>
    <col :width="fieldW"/>
  </colgroup>
  <tbody>
    <tr v-for="(def,i) in defs.filter(d => d)" :key="`def${i}`" :class="{'d-none': def.active===false}">
      <td class="label-cell pr-2" v-if="hideLabels !== true" :class="{invis:!def.lbl}" :style="tdStyle(def)">
        <h3 v-if="def.title" :text="def.title"
          :class="{[def.titleClass||'none']: def.titleClass || false}"
          style="margin:0 0 0 0;padding:12px 0 8px 0">
          {{ def.title }}
        </h3>
        <label :for="def.guid" :class="labelClass(def)">
          <b-icon-info-circle v-b-tooltip.top.hover.v-dark="def.labelTip" v-if="def.labelTip" class="mr-1"/>
          <span>{{labelText(def)}}</span>
          <span v-if="labelText2(def)"><br>{{labelText2(def)}}</span>
        </label>
        <div class="small" v-if="def.sublabel">{{ def.sublabel }}</div>
      </td>
      <td class="field-cell" :style="tdStyle(def)">
        <h3 v-if="def.title" :style="{height:`${def.tHeight}px`}" class="invis" style="margin:0 0 0 0;padding:12px 0 0 0">...</h3>
        <field :def="def" :multitip="multitip(def)" :style="dynamicH(def)"/>

      </td>
    </tr>
  </tbody>
</table>
</template>

<script>

import {mapGetters} from 'vuex';
import Field from '@/components/fields/field';

export default {
  name: 'field-column',
  components: {Field},
  computed: {
    ...mapGetters('getQuote', ['itemFromChain', 'buildings']),
    dynamicH(){
      return def => {
        let css = {};
        let h = def.clientHeight;
        if (h) {
          //debugger;
          css.height = `${h}px`;
          css.marginBottom = '10px';
        }
        return css;
      };
    },
    tdStyle(){
      return def => {
        if (def.height) {
          return {
            height: `${def.height}px`,
            verticalAlign: 'text-top'
          };
        }
        return {};
      };
    },
    labelText(){
      return def => {
        let lbl = def?.lbl ?? '';
        if (lbl.includes('\n')) {
          lbl = lbl.split('\n')[0];
        }
        return lbl;
      };
    },
    labelText2(){
      return def => {
        let lbl = def?.lbl ?? '';
        if (lbl.includes('\n')) {
          return lbl.split('\n')[1];
        }
        return null;
      };
    },
    labelClass(){
      return def => {
        let {lblClass} = def;

        return {
          [lblClass]: !!lblClass,
          valid: this.valid(def) === true,
          invalid: this.valid(def) === false,
          isRequired: def.optional !== true && def.isRequired
        };
      };
    },
    multitip(){
      return ({state, chains, vals, selectionVals, type}) => {


        let hasTip = state && state === 'multi' ? 'Multiple Values' : null;

        if (hasTip) {
          let uniq = {};
          selectionVals.forEach((v, i) => {
            if (uniq[v]) {
              uniq[v].push(chains[i]);
            } else {
              uniq[v] = [chains[i]];
            }
          });
          if (Object.keys(uniq).length === 1) {
            return false;
          }

          return Object.keys(uniq).map(v => {
            let lbl = v === 'null' ? 'Not set' :
              type === 'select' ?
                vals.find(kv => `${kv.key}` === `${v}`) : v;

            if (type === 'select' && this.isType.object(lbl)) {
              lbl = lbl.lbl;
            }
            let count = uniq[v].length;
            if (lbl === 'null') {
              lbl = 'Not set';
            }
            return {lbl, count};

          });
        }
        return false;
      };
    },
    valid(){
      return item => {

        if (item) {

          let valid = item.isRequired ? item.valid : null;
          let touched = item.touched === true;
          let blurred = item.blurred === true;
          if (!blurred) {
            return undefined;
          }
          if (valid !== false) {
            return valid;
          }
          if (!touched || !blurred) {
            return undefined;
          }
          return false;
        }
        return false;
      };
    }


  },
  props: ['defs', 'labelW', 'fieldW', 'hideLabels', 'hideFields'],
  mounted() {
    //console.log({fieldColumn: this.defs});
  }
};
</script>

<style scoped lang="scss">
.label-cell{
  position:relative;
  .geo-lbl{
    position:absolute;
    top:10px;
  }
}
.field-cell{}
@import "../../assets/scss/variables";
h3{
  color:$coral;
}
h3.subtitle{
  font-weight: bold;
  color:$navy;
  font-size: 15px;
  text-transform: none;
  margin-bottom: 16px;
}
h3.titleSpan{
  position:relative;
  color:transparent;
  &:after{
    content:attr(text);
    position:absolute;
    color:$primary;
    left:0;
    width:888px;
    top:12px;
  }
}
h3.titleFixed{
  height:33px;
}
td{
  height:55px;

  label {
    &.valid {
      color: #333;
      font-weight: 100;
    }

    &.invalid {
      color: $danger;
    }
    &.isRequired{
      font-weight: 900;
      &:before{
        content:'* ';
        font-weight: 900;
        color:$primary;

        vertical-align: middle;
      }
    }
    &.optional{
      font-weight: 100 !important;
      &:before{
        display:none;
      }
    }
  }

}
</style>
